import { useContext, useState } from "react";
import http, { AxiosResponse, AxiosError } from "components/http-util";
import { Table, message, TablePaginationConfig, Modal } from "antd";
import type { TableProps } from "antd";
import dayjs from "dayjs";
import { useDeepCompareEffect } from "react-use";
import { ProcessButton } from "./process-button";
import ProcessModal from "./process-modal";
import {
  RightsOrderContext,
  RightsOrderProvider,
} from "./rights-order-context";
import { RightsOrderT } from "./rights-order-context";

const columns: TableProps<RightsOrderT>["columns"] = [
  {
    title: "姓名",
    dataIndex: "userName",
  },
  {
    title: "权益卡号",
    dataIndex: "cardId",
  },
  {
    title: "权益名称",
    dataIndex: "rightsName",
  },
  {
    title: "证件",
    dataIndex: "idNo",
    render: (_, record) => {
      return `${record.idNo}(${record.idType})`;
    },
  },
  {
    title: "联系方式",
    dataIndex: "mobile",
  },
  {
    title: "时间",
    dataIndex: "activateDate",
    render: (_, record) => {
      return (
        <>
          激活时间：{dayjs(record.activateDate).format("YYYY-MM-DD")}<br />
          登记时间：{dayjs(record.orderTime).format("YYYY-MM-DD HH:mm:ss")}
        </>
      );
    },
  },
  {
    title: "处理记录",
    dataIndex: "comments",
  },
  {
    title: "操作",
    dataIndex: "state",
    render: (_, record) => {
      return <ProcessButton record={record} />;
    },
  },
];

export default function RightsOrder() {
  const [orders, setOrders] = useState([] as RightsOrderT[]);
  const [loading, setLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      pageSizeOptions: [10, 30, 50, 100],
      total: 0,
    } as TablePaginationConfig,
  });

  useDeepCompareEffect(() => {
    http
      .get("/ph-admin/oper/rights-order", {
        params: {
          page: tableParams.pagination.current,
          pageSize: tableParams.pagination.pageSize,
        },
      })
      .then((res: AxiosResponse<RightsOrderT[]>) => {
        setLoading(false);
        setOrders(res.data);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.length ? res.data[0].total : 0,
          },
        });
      })
      .catch((e: AxiosError) => {
        message.error(e.response ? (e.response.data as string) : "未知错误");
      });
  }, [tableParams]);
  return (
    <RightsOrderProvider>
      <Table
        columns={columns}
        rowKey={(r) => r.id}
        loading={loading}
        dataSource={orders}
        pagination={tableParams.pagination}
        onChange={(pagination) => {
          let p = { ...tableParams };
          console.log(pagination);
          p.pagination = pagination;
          setTableParams(p);
        }}
      />
      <ProcessModal />
    </RightsOrderProvider>
  );
}
