import { useState, useEffect, useContext } from 'react';
import type { MenuProps } from "antd";
import { Flex, Image, Layout, Menu } from "antd";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import type { Location } from "react-router-dom";
import { PageContext, PageDispatchContext } from 'components/page-context';
import "./main-page.css";
import { UserInfoTag } from "pages/user-info";

const { Content, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];


function CalcMenuItem(location: Location<any>): string[] {
  var menus: MenuItem[] = [
    {
      key: "rightscard/list",
      label: "权益卡列表",
    },
    {
      key: "rightscard/cardcfg",
      label: "权益卡配置",
    },
    {
      key: "rightscard/rights-order",
      label: "权益使用登记",
    },
  ];

  const result = menus.filter((v: MenuItem) => {
    return location.pathname.endsWith(v?.key ? (v.key as string) : "undefined");
  });
  return result.length ? [result[0]?.key as string] : [""];
}

export default function MainPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const pageContext = useContext(PageContext);
  let userInfoMap = pageContext.userInfo;
  var menus: MenuItem[] = [
    {
      key: "rightscard/list",
      label: "权益卡列表",
    },
    {
      key: "rightscard/cardcfg",
      label: "权益卡配置",
    },
    {
      key: "rightscard/rights-order",
      label: "权益使用登记",
    },
  ];

  if (userInfoMap.get("channel") != null && userInfoMap.get("channel") > 0) {
    menus = [
      {
        key: "rightscard/list",
        label: "权益卡列表",
      }
    ];
  }

  return (
    <Layout style={{ minWidth: "100vw", minHeight: "100vh" }}>
      <Sider
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Flex vertical justify="space-between" style={{ height: "100%" }}>
          <div>
            <Image src="/logo.jpg" preview={false} />
            <Menu
              style={{ marginTop: 20 }}
              mode="inline"
              theme="dark"
              defaultSelectedKeys={CalcMenuItem(location)}
              items={menus}
              onClick={({ key }: { key: string }) => {
                navigate(key);
              }}
            />
          </div>
          <UserInfoTag />
        </Flex>
      </Sider>
      <Layout style={{ marginLeft: 200 }}>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
