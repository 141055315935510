import { Button, Descriptions, Input, message, Modal } from "antd";
import http from "components/http-util";
import { useContext, useState, useEffect } from "react";
import { RightsOrderContext } from "./rights-order-context";
import type { DescriptionsProps } from "antd";
import dayjs from "dayjs";

export default function ProcessModal() {
  const { ctx, dispatch } = useContext(RightsOrderContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [comments, setComments] = useState<string | undefined>("");

  useEffect(() => {
    setComments(ctx.record?.comments);
  }, [ctx.record?.comments]);

  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "姓名",
      children: ctx.record?.userName,
    },
    {
      key: "2",
      label: "权益卡号",
      children: ctx.record?.cardId,
    },
    {
      key: "3",
      label: "权益名称",
      children: ctx.record?.rightsName,
    },
    {
      key: "4",
      label: "证件",
      children: ctx.record?.idNo + "(" + ctx.record?.idType + ")",
    },
    {
      key: "5",
      label: "联系方式",
      children: ctx.record?.mobile,
    },
    {
      key: "6",
      label: "激活时间",
      children: dayjs(ctx.record?.activateDate).format("YYYY-MM-DD"),
    },
    {
      key: "7",
      label: "登记时间",
      children: dayjs(ctx.record?.orderTime).format("YYYY-MM-DD HH:mm:ss"),
    },
  ];

  const onCancel = () => {
    dispatch({
      type: "clear-process-rights-order",
      record: null,
    });
  };

  const onConfirm = () => {
    http
      .post("/ph-admin/oper/process-rights-order", {
        id: ctx.record?.id,
        comments,
      })
      .then((_) => {
        dispatch({
          type: "clear-process-rights-order",
          record: null,
        });
      })
      .catch((e) => {
        messageApi.error("状态流转错误：" + e.response.data);
      });
  };

  const onFinish = () => {
    http
      .post("/ph-admin/oper/finish-rights-order", {
        id: ctx.record?.id,
      })
      .then((_) => {
        dispatch({
          type: "clear-process-rights-order",
          record: null,
        });
      })
      .catch((e) => {
        messageApi.error("状态流转错误：" + e.response.data);
      });
  };

  const buttons = () => {
    if (ctx.record?.state === "finish") {
      return (
        <>
          <Button onClick={onCancel} type="primary">
            确认
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button onClick={onCancel}>取消</Button>
          <Button onClick={onFinish}>标记为已完成</Button>
          <Button>扣减权益次数</Button>
          <Button type="primary" onClick={onConfirm}>
            确认
          </Button>
        </>
      );
    }
  };

  return (
    <Modal
      title="处理报案"
      open={ctx.visible}
      closable={false}
      keyboard={false}
      maskClosable={false}
      width={"62%"}
      footer={buttons}
    >
      {contextHolder}
      <Descriptions title={null} items={items} />
      <br />
      <Input.TextArea
        disabled={ctx.record?.state === "finish"}
        rows={6}
        allowClear={false}
        value={comments}
        onChange={(e) => {
          setComments(e.currentTarget.value);
        }}
        onPressEnter={(e) => setComments(e.currentTarget.value)}
      />
    </Modal>
  );
}
