import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LoginPage from "./pages/login-page";
import RightsCardList from "./pages/rightscard-list";
import MainPage from "pages/main-page";
import RightsCardCfg from "pages/rightscard-cfg";
import CardBindDetail from "pages/cardbinddetail";
import RightsOrder from "pages/rights-order/rights-order";
import UserInfoPage from "pages/user-info";
import { PageProvider } from "components/page-context";

function App() {
  return (
    <div className="App">
      <PageProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/main" element={<MainPage />}>
              <Route path="userinfo/:username" element={<UserInfoPage />} />
              <Route path="rightscard/list" element={<RightsCardList />} />
              <Route path="rightscard/cardcfg" element={<RightsCardCfg />} />
              <Route
                path="rightscard/binddetail/:cardid"
                element={<CardBindDetail />}
              />
              <Route
                path="rightscard/rightscfg/:cardtype"
                element={<RightsCardList />}
              />
              <Route path="rightscard/rights-order" element={<RightsOrder />} />
            </Route>
          </Routes>
        </Router>
      </PageProvider>
    </div>
  );
}

export default App;
