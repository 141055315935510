import { Spin, Table, message } from "antd";
import type { TableProps } from "antd";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import http, { AxiosResponse, AxiosError } from "components/http-util";

interface BindUser {
  userId: string;
  memberName: string;
  idType: string;
  idNo: string;
  age: number;
  mobile: string;
  relation: string;
  city: string[];
}

interface RightsUsage {
  rightsId: number;
  rightsName: string;
  leftTimes: number;
  totalTimes: number;
}

interface BindDetail {
  cardId: string; // 卡编号
  openid: string; // 所属openid
  bindUser: BindUser[]; // 绑定人信息
}

const bindUserColumns: TableProps<BindUser>["columns"] = [
  {
    title: "姓名",
    dataIndex: "memberName",
  },
  {
    title: "证件类型",
    dataIndex: "idType",
  },
  {
    title: "证件号码",
    dataIndex: "idNo",
  },
  {
    title: "年龄",
    dataIndex: "age",
  },
  {
    title: "手机号",
    dataIndex: "mobile",
  },
  {
    title: "关系",
    dataIndex: "relation",
  },
  {
    title: "地区",
    dataIndex: "city",
    render: (_, record) => {
      return record.city.join(", ");
    },
  },
];

const rightsUsageColumns: TableProps<RightsUsage>["columns"] = [
  {
    title: "权益名称",
    dataIndex: "rightsName",
  },
  {
    title: "剩余次数",
    dataIndex: "leftTimes",
    render: (_, record) => {
      if (record.totalTimes === -1) {
        return "无限次";
      } else {
        return `剩余${record.leftTimes}次/共${record.totalTimes}次`;
      }
    },
  },
];

export default function CardBindDetail() {
  const params = useParams();
  const [detailLoading, setDetailLoading] = useState(true);
  const [usageLoading, setUsageLoading] = useState(true);
  const [bindUser, setBindUser] = useState([] as BindUser[]);
  const [rightsUsage, setRightsUsage] = useState([] as RightsUsage[]);

  useEffect(() => {
    http
      .get("/ph-admin/oper/bind-detail", {
        params: {
          cardId: params.cardid,
        },
      })
      .then((res: AxiosResponse<BindDetail>) => {
        setDetailLoading(false);
        setBindUser(res.data.bindUser);
      })
      .catch((e: AxiosError) => {
        message.error(e.response ? (e.response.data as string) : "未知错误");
      });
  }, [params]);

  useEffect(() => {
    http
      .get("/ph-admin/oper/rights-usage", {
        params: {
          cardId: params.cardid,
        },
      })
      .then((res: AxiosResponse<RightsUsage[]>) => {
        setUsageLoading(false);
        setRightsUsage(res.data);
      })
      .catch((e: AxiosError) => {
        message.error(e.response ? (e.response.data as string) : "未知错误");
      });
  }, [params]);

  return (
    <>
      <Spin spinning={detailLoading}>
        <Table
          dataSource={bindUser}
          columns={bindUserColumns}
          rowKey={(r) => r.userId}
        />
      </Spin>
      <Spin spinning={usageLoading}>
        {/* // 权益剩余次数信息 */}
        <Table
          dataSource={rightsUsage}
          columns={rightsUsageColumns}
          rowKey={(r) => r.rightsId}
        />
      </Spin>
    </>
  );
}
