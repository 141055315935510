import { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Button, Alert } from 'antd';
import 'App.css';
import './login-page.css';
import http from 'components/http-util';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
  const [alertMsg, setAlertMsg] = useState('');
  const navigate = useNavigate();
  const [cookies] = useCookies(['X-PH-ADMIN-SESSION']);

  useEffect(() => {
    if (alertMsg !== '') {
      setTimeout(() => {
        setAlertMsg('');
      }, 3000);
    }
  }, [alertMsg]);

  useEffect(() => {
    const c = cookies['X-PH-ADMIN-SESSION'];
    if (c) {
      navigate('/main/rightscard/list');
    }
  }, [navigate, cookies]);

  return (
    <div>
      <h1 className='admin-title'>普朗家</h1>
      <div>
        <Form
          name='basic'
          className='login-window'
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={(values) => {
            http
              .post('/ph-admin/portal/login', {
                username: values.username,
                password: values.password,
              })
              .then((_) => {
                // console.log(res);
                navigate('/main/rightscard/list');
              })
              .catch((e) => {
                setAlertMsg(e.response.data);
              });
          }}
          autoComplete='off'
        >
          <Form.Item
            label='用户名'
            name='username'
            rules={[
              {
                required: true,
                message: '请输入用户名',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='密码'
            name='password'
            rules={[
              {
                required: true,
                message: '请输入密码',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          {alertMsg === '' ? '' : <Alert message={alertMsg} type='error' />}

          <Form.Item
            name='remember'
            valuePropName='checked'
            wrapperCol={{
              offset: 5,
              span: 16,
            }}
          >
            <Checkbox>下次自动登录</Checkbox>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 5,
              span: 16,
            }}
          >
            <Button type='primary' htmlType='submit'>
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
