import { useState, useEffect, useContext, Fragment } from 'react';
import {
  Table,
  Popover,
  Descriptions,
  TablePaginationConfig,
  Spin,
  message,
  Switch,
  Flex,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Upload,
} from 'antd';

import type { TableProps } from 'antd';
import type { UploadProps } from 'antd';

import { Link } from 'react-router-dom';
import './rightscard-list.css';
import http, { AxiosResponse, AxiosError } from 'components/http-util';
import dayjs from 'dayjs';
import { PageContext, PageDispatchContext } from 'components/page-context';
import type { GetCardListReq } from 'interfaces';
import { useDeepCompareEffect } from 'react-use';
const { RangePicker } = DatePicker;

interface CardType {
  cardId: string; // 卡编号
  cardNo: string; // 激活码
  cardType: string; // 卡类型
  openid: string; // 如果已绑定，则为用户id
  activateDate: string; // 激活时间
  channel: number; // 渠道
  validYear: number; // 有效期
  cardClass: number; // 卡片类型：个人卡/家庭卡
  appendOpenId: string; //绑定的openid
}

interface BindInfo {
  cardId: string; // 卡编号
  bindNum: number; // 已绑定人数
}

function BaseBindInfo({ cardId, openid }: { cardId: string; openid: string }) {
  const [bindCnt, setBindCnt] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    http
      .get('/ph-admin/oper/bind-info', {
        params: {
          cardId,
          openid,
        },
      })
      .then((res: AxiosResponse<BindInfo>) => {
        setBindCnt(res.data.bindNum);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        message.error(e.response ? (e.response.data as string) : '未知错误');
      });
  }, [cardId, openid]);

  return (
    <Spin spinning={loading}>
      <Descriptions
        title="基本绑定信息"
        column={2}
        bordered
        layout="vertical"
        extra={
          <Link to={`/main/rightscard/binddetail/${cardId}`}>查看绑定详情</Link>
        }
      >
        <Descriptions.Item label="卡号">{cardId}</Descriptions.Item>
        <Descriptions.Item label="微信openid">{openid}</Descriptions.Item>
        <Descriptions.Item label="已绑定人数">{bindCnt}</Descriptions.Item>
      </Descriptions>
    </Spin>
  );
}

function BuildTableColumnDefine() {
  const pageContext = useContext(PageContext);
  const columns: TableProps<CardType>['columns'] = [
    {
      title: '卡号',
      dataIndex: 'cardId',
    },
    {
      title: '激活码',
      dataIndex: 'cardNo',
    },
    {
      title: '权益卡类型',
      dataIndex: 'name',
    },
    {
      title: '绑定信息',
      dataIndex: 'openid',
      render: (text, record) => {
        let userInfo = pageContext.userInfo;
        let userChannel =
          userInfo.get('channel') == null ? 1 : userInfo.get('channel');
        if (text === '') {
          return <span>未绑定</span>;
        } else {
          return userChannel < 0 ? (
            <Popover
              rootClassName="base-bind-info"
              content={
                <BaseBindInfo
                  cardId={record.cardId}
                  openid={record.appendOpenId}
                />
              }
              trigger="hover"
            >
              <span>已绑定</span>
            </Popover>
          ) : (
            <span>已绑定</span>
          );
        }
      },
    },
    {
      title: '激活日期',
      dataIndex: 'activateDate',
      render: (text, record) => {
        if (record.activateDate === null) {
          return '';
        } else {
          return dayjs(text).format('YYYY-MM-DD');
        }
      },
    },
    {
      title: '渠道',
      dataIndex: 'channel',
    },
    {
      title: '卡片有效期',
      dataIndex: 'validYear',
      render: (text) => {
        return <span>{text}年</span>;
      },
    },
  ];

  return columns;
}

interface CardListRsp {
  cardList: CardType[];
  total: number;
}

function CardIdQuery() {
  const pageContext = useContext(PageContext);
  const pageContextDispatch = useContext(PageDispatchContext);

  const [cardId, setCardId] = useState('');
  const [common, setCommon] = useState('');
  const [province, setProvince] = useState<Array<any>>([]);
  const [provinceId, setProvinceId] = useState(0);
  const [city, setCity] = useState<Array<any>>([]);
  const [cityId, setCityId] = useState(0);
  const [district, setDistrict] = useState<Array<any>>([]);
  const [districtId, setDistrictId] = useState(0);
  const [exporting, setExporting] = useState(false);
  const [importing, setImporting] = useState(false);

  useEffect(() => {
    setCardId(pageContext.RightsCardList.cardId);
    setCommon(pageContext.RightsCardList.common);
    http.get('/province.json').then((r) => {
      var provinceMap = new Map();
      var provinceList = [];
      for (let i = 0; i < r.data.length; i++) {
        provinceMap.set(r.data[i].ProID, r.data[i].name);
        provinceList.push({
          value: r.data[i].ProID,
          label: r.data[i].name,
        });
      }
      pageContextDispatch({
        type: 'set-province',
        provinceMap,
      });
      setProvince(provinceList);
    });
    http.get('/city.json').then((r) => {
      var cityMap = new Map();
      var cityList = [];
      for (let i = 0; i < r.data.length; i++) {
        cityMap.set(r.data[i].CityID, r.data[i].name);
        cityList.push({
          ProID: r.data[i].ProID,
          value: r.data[i].CityID,
          label: r.data[i].name,
        });
      }
      pageContextDispatch({
        type: 'set-city',
        cityMap,
      });
      setCity(cityList);
    });
    http.get('/district.json').then((r) => {
      var districtMap = new Map();
      var districtList = [];
      for (let i = 0; i < r.data.length; i++) {
        districtMap.set(r.data[i].Id, r.data[i].name);
        districtList.push({
          CityID: r.data[i].CityID,
          value: r.data[i].Id,
          label: r.data[i].DisName,
        });
      }
      pageContextDispatch({
        type: 'set-district',
        districtMap,
      });
      setDistrict(districtList);
    });
  }, [pageContext.RightsCardList.cardId]);
  let locationChoice = new Array<number>();
  locationChoice.push(provinceId);
  locationChoice.push(cityId);
  locationChoice.push(districtId);
  const setQuery = () => {
    pageContextDispatch({
      type: 'set-cardid',
      cardId,
    });
    pageContextDispatch({
      type: 'set-common',
      common,
    });
    pageContextDispatch({
      type: 'set-location',
      locationChoice,
    });
  };

  let filteredCityList = [];
  for (let i = 0; i < city.length; i++) {
    if (city[i].ProID === provinceId) {
      filteredCityList.push(city[i]);
    }
  }
  // console.log("District : ", district, "province id : ", provinceId, "city id : ", cityId);
  let filteredDistrictList = [];
  for (let i = 0; i < district.length; i++) {
    if (district[i].CityID === cityId) {
      filteredDistrictList.push(district[i]);
    }
  }

  let d1 = dayjs(pageContext.range.get('start'));
  let d2 = dayjs(pageContext.range.get('end'));

  const props: UploadProps = {
    name: 'file',
    action: '/ph-admin/java/data/upload',
    showUploadList: false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        setImporting(true);
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        setImporting(false);
        alert('处理成功');
      }
      if (info.file.status === 'error') {
        setImporting(false);
        alert('处理失败 : \n' + info.file.response);
      }
    },
  };

  return (
    <Fragment>
      <Flex
        style={{ width: '60vw', marginLeft: '20px' }}
        align="center"
        className="right-card-list-panel"
      >
        <span>只显示已绑定：</span>
        <Switch
          checkedChildren="开启"
          unCheckedChildren="关闭"
          checked={pageContext.RightsCardList.binded}
          onChange={(checked: boolean) =>
            pageContextDispatch({
              type: 'set-binded',
              binded: checked,
            })
          }
        />
        {<span style={{ whiteSpace: 'nowrap', marginLeft: 20 }}>卡号：</span>}
        <Input
          value={cardId}
          allowClear
          maxLength={20}
          style={{ width: 200 }}
          onChange={(e) => {
            setCardId(e.target.value);
          }}
          onPressEnter={setQuery}
        />
        {
          <span style={{ whiteSpace: 'nowrap', marginLeft: 20 }}>
            模糊查询：
          </span>
        }
        <Input
          value={common}
          allowClear
          maxLength={20}
          style={{ width: 200 }}
          onChange={(e) => {
            setCommon(e.target.value);
          }}
          onPressEnter={setQuery}
        />
      </Flex>
      <Flex
        className="right-card-list-panel"
        style={{ width: '60vw', marginLeft: '20px' }}
        align="center"
      >
        <span style={{ whiteSpace: 'nowrap', display: 'block' }}>
          选择地区：
        </span>
        <Select
          defaultValue="请选择"
          style={{ width: 120 }}
          onChange={(e) => {
            setProvinceId(parseInt(e));
          }}
          options={province}
        />
        <Select
          defaultValue="请选择"
          style={{ width: 120 }}
          onChange={(e) => {
            setCityId(parseInt(e));
          }}
          options={filteredCityList}
        />
        <Select
          defaultValue="请选择"
          style={{ width: 120 }}
          onChange={(e) => {
            setDistrictId(parseInt(e));
          }}
          options={filteredDistrictList}
        />
        <span
          style={{
            whiteSpace: 'nowrap',
            display: 'block',
            marginLeft: 20,
          }}
        >
          销售时间：
        </span>
        <Space direction="vertical" size={12} style={{ marginRight: 20 }}>
          <RangePicker
            value={[d1, d2]}
            onChange={(moment) => {
              if (moment != null) {
                console.log(
                  'Range picker change : ',
                  moment[0]?.toDate(),
                  moment[0]?.toDate().getTime(),
                  moment[1]?.toDate(),
                  moment[1]?.toDate().getTime(),
                );
                let range = new Map<any, any>();
                range.set('start', moment[0]?.toDate().getTime());
                range.set('end', moment[1]?.toDate().getTime());
                pageContextDispatch({
                  type: 'set-range',
                  range,
                });
              }
            }}
          />
        </Space>
        <Button
          style={{ marginRight: 20 }}
          type="default"
          onClick={() => {
            let range = new Map<any, any>();
            range.set('start', 0);
            range.set('end', 0);
            pageContextDispatch({
              type: 'set-range',
              range,
            });
          }}
        >
          清除日期
        </Button>
        <Button style={{ marginRight: 20 }} type="primary" onClick={setQuery}>
          查询
        </Button>
        <Spin spinning={exporting} delay={500}>
          <Button
            style={{ marginRight: 20 }}
            type="primary"
            onClick={() => {
              setExporting(true);
              http
                .post('/ph-admin/java/card/export-card', {
                  cardId:
                    pageContext.RightsCardList.cardId === ''
                      ? undefined
                      : pageContext.RightsCardList.cardId,
                  bindState: pageContext.RightsCardList.binded ? 1 : 0,
                  start:
                    pageContext.range.get('start') == null
                      ? 0
                      : pageContext.range.get('start'),
                  end:
                    pageContext.range.get('end') == null
                      ? 0
                      : pageContext.range.get('end'),
                })
                .then((r: AxiosResponse<CardListRsp>) => {
                  setExporting(false);
                  if (r.status === 200) {
                    window.open(
                      '/ph-admin/java/data/download?fileName=' + r.data,
                    );
                  } else {
                    alert(r.data);
                  }
                })
                .catch((e: AxiosError) => {
                  setExporting(false);
                  message.error(
                    e.response ? (e.response.data as string) : '未知错误',
                  );
                });
            }}
          >
            导出
          </Button>
        </Spin>
        <Spin spinning={importing} delay={500}>
          <Upload {...props}>
            <Button type="primary">上传导入</Button>
          </Upload>
        </Spin>
      </Flex>
    </Fragment>
  );
}

export default function RightsCardList() {
  // 从后端获取卡片列表
  const [cards, setCards] = useState([] as CardType[]);
  const [loading, setLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      pageSizeOptions: [10, 30, 50, 100],
      total: 0,
    } as TablePaginationConfig,
  });
  const pageContext = useContext(PageContext);
  const pageContextDispatch = useContext(PageDispatchContext);

  console.log('Page context in render : ', pageContext);

  useDeepCompareEffect(() => {
    const params: GetCardListReq = {
      page: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,
      binded: pageContext.RightsCardList.binded,
      cardId:
        pageContext.RightsCardList.cardId === ''
          ? undefined
          : pageContext.RightsCardList.cardId,
      common:
        pageContext.RightsCardList.common === ''
          ? undefined
          : pageContext.RightsCardList.common,
    };
    // 按卡号查询时，binded标识无效
    if (params.cardId) {
      params.binded = false;
    }
    if (pageContext.locationChoice.length > 0) {
      if (
        pageContext.locationChoice[0] > 0 &&
        pageContext.locationChoice[1] > 0 &&
        pageContext.locationChoice[2] > 0
      ) {
      }
    }

    console.log('Page context before request : ', pageContext);
    http
      .post('/ph-admin/java/card/admin-list', {
        customizedPageSize: tableParams.pagination.pageSize,
        currentPage:
          tableParams.pagination.current == null
            ? 0
            : tableParams.pagination.current - 1,
        cardId: params.cardId,
        common: params.common,
        bindState: params.binded ? 1 : 0,
        start:
          pageContext.range.get('start') == null
            ? 0
            : pageContext.range.get('start'),
        end:
          pageContext.range.get('end') == null
            ? 0
            : pageContext.range.get('end'),
      })
      .then((r: AxiosResponse<CardListRsp>) => {
        console.log('admin-list return : ', r);
        setLoading(false);
        setCards(r.data.cardList);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: r.data.total,
          },
        });
      })
      .catch((e: AxiosError) => {
        message.error(e.response ? (e.response.data as string) : '未知错误');
      });
  }, [
    pageContext.RightsCardList.binded,
    pageContext.RightsCardList.cardId,
    pageContext.RightsCardList.common,
    pageContext.locationChoice,
    pageContext.range.get('start'),
    pageContext.range.get('end'),
    tableParams,
  ]);

  return (
    <div>
      <CardIdQuery />
      <Table
        columns={BuildTableColumnDefine()}
        rowKey={(r) => r.cardId}
        loading={loading}
        dataSource={cards}
        pagination={tableParams.pagination}
        onChange={(pagination) => {
          let p = { ...tableParams };
          console.log(pagination);
          p.pagination = pagination;
          setTableParams(p);
        }}
      />
    </div>
  );
}
