import { Button } from "antd";
import { useContext } from "react";
import { RightsOrderContext, RightsOrderT } from "./rights-order-context";

export function ProcessButton({ record }: { record: RightsOrderT }) {
  const { ctx, dispatch } = useContext(RightsOrderContext);

  const text = (state: string | undefined | null) => {
    switch (state) {
      case "proc":
        return "处理中";
      case "finish":
        return "已完成";
      default:
      case "new":
        return "待处理";
    }
  };

  return (
    <Button
      type={record?.state === "finish" ? "default" : "primary"}
      onClick={() => {
        console.log("set record" + JSON.stringify(record));
        dispatch({ type: "set-process-rights-order", record: record });
      }}
    >
      {text(record.state)}
    </Button>
  );
}
