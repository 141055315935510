import { useState, useEffect } from "react";
import { Table } from "antd";
import type { TableProps } from "antd";
import { Link } from "react-router-dom";

interface CardCfg {
  cardCfgId: string;
  cardName: string;
  cardImg: string;
}

const columns: TableProps<CardCfg>["columns"] = [
  {
    title: "卡类型ID",
    dataIndex: "cardCfgId",
    key: "cardCfgId",
  },
  {
    title: "权益卡名称",
    dataIndex: "cardName",
    key: "cardName",
  },
  {
    title: "权益卡图片",
    dataIndex: "cardImg",
    key: "cardImg",
  },
  {
    title: "权益配置",
    key: "cardRights",
    render: (_, record) => {
      return (
        <Link to={`/main/rightscard/rightscfg/${record.cardCfgId}`}>
          点击查看
        </Link>
      );
    },
  },
];

export default function RightsCardCfg() {
  const [cards, setCards] = useState([] as CardCfg[]);

  useEffect(() => {
    // 假数据
    setCards([
      {
        cardCfgId: "tcr-t-tils",
        cardName: "非凡IT 甄选权益卡",
        cardImg: "tmp-card-image.png",
      },
      {
        cardCfgId: "tcr-t-tils-f",
        cardName: "龙腾权益卡",
        cardImg: "tmp-card-image.png",
      },
    ] as CardCfg[]);
  }, []);

  return <Table columns={columns} dataSource={cards} />;
}
