import { Avatar, Button, Flex, Form, Input, message } from 'antd';
import http, { AxiosResponse } from 'components/http-util';
import React, { useEffect, useState, useContext } from 'react';
import { PageContext, PageDispatchContext } from 'components/page-context';
import { useCookies } from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';


type UserInfoT = {
  nickname: string;
  username: string;
};

function Tag({ username, nickname }: UserInfoT) {
  const [, , removeCookie] = useCookies(['X-PH-ADMIN-SESSION']);
  const navigate = useNavigate();
  return (
    <Flex vertical align='center'>
      <Link
        style={{
          color: 'white',
        }}
        to={`/main/userinfo/${username}`}
      >
        <Flex vertical align='center'>
          <Avatar
            style={{
              backgroundColor: '#bfbfbf',
            }}
            size={64}
            icon={<UserOutlined />}
          />
          <div style={{ minHeight: '10px' }}></div>
          {nickname}
        </Flex>
      </Link>

      <div style={{ minHeight: '10px' }}></div>
      <Button
        type='text'
        size='small'
        style={{ color: 'white' }}
        onClick={() => {
          removeCookie('X-PH-ADMIN-SESSION', {
            path: '/',
          });
          navigate('/');
        }}
      >
        退出登录
      </Button>
    </Flex>
  );
}

export function UserInfoTag() {
  const [login, setLogin] = useState(false);
  const [userInfo, setUserInfo] = useState({} as UserInfoT);
  const [cookies] = useCookies(['X-PH-ADMIN-SESSION']);
  const pageContext = useContext(PageContext);
  const pageContextDispatch = useContext(PageDispatchContext);

  useEffect(() => {
    const c = cookies['X-PH-ADMIN-SESSION'];
    http.post('/ph-admin/java/user/admin-session',
      {
      })
      .then((r: AxiosResponse<any>) => { 
        console.log("Session post : ", r);

        if(r.status === 200){
          let buildMap = new Map<any, any>;
          buildMap.set("username", r.data.username);
          buildMap.set("channel", r.data.channel);
          pageContextDispatch({
            type: 'set-user-info',
            userInfo: buildMap
          })
        }
      })
    if (c) {
      http
        .get('/ph-admin/admin/get-selfinfo')
        .then((res: AxiosResponse<UserInfoT>) => {
          setLogin(true);
          setUserInfo({
            username: res.data.username,
            nickname: res.data.nickname,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setLogin(false);
    }
  }, [cookies]);

  return (
    <Flex
      style={{
        marginBottom: '30px',
      }}
      vertical
      justify='center'
      align='center'
    >
      {login ? (
        <Tag username={userInfo.username} nickname={userInfo.nickname} />
      ) : (
        <span style={{ color: 'white' }}>未登录</span>
      )}
    </Flex>
  );
}

type FieldType = {
  username: string;
  nickname: string;
  currentPass: string;
  newPass: string;
  newPass2: string;
};

function passwordCheck(pass: string): Promise<void> {
  const counts: { [key in string]: number } = {
    digits: 0,
    uppercase: 0,
    lowercase: 0,
    special: 0,
  };

  const msg =
    '新密码至少长8位，且包含大写字母/小写字母/数字/标点符号中的至少3种';
  if (!/^[\x20-\x7E]*$/.test(pass) || pass.length < 8) {
    return Promise.reject(msg);
  }

  for (let i = 0; i < pass.length; i++) {
    const char = pass[i];
    if (/\d/.test(char)) {
      counts.digits++;
    } else if (/[A-Z]/.test(char)) {
      counts.uppercase++;
    } else if (/[a-z]/.test(char)) {
      counts.lowercase++;
    } else {
      counts.special++;
    }
  }

  const kinds = Object.keys(counts).reduce((acc: number, curr: string) => {
    return acc + (counts[curr] > 0 ? 1 : 0);
  }, 0);
  if (kinds < 3) {
    return Promise.reject(msg);
  }
  return Promise.resolve();
}

export function UserInfoPage() {
  const [cookies] = useCookies(['X-PH-ADMIN-SESSION']);
  const [form] = Form.useForm();

  useEffect(() => {
    const c = cookies['X-PH-ADMIN-SESSION'];
    if (c) {
      http
        .get('/ph-admin/admin/get-selfinfo')
        .then((res: AxiosResponse<UserInfoT>) => {
          // console.log(res);
          form.setFieldValue('username', res.data.username);
          form.setFieldValue('nickname', res.data.nickname);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      message.error('未登录');
    }
  }, [cookies, form]);

  return (
    <Form
      validateTrigger={['onChange', 'onBlur']}
      form={form}
      style={{
        maxWidth: 600,
        margin: '0 auto',
      }}
      labelCol={{
        span: 5,
      }}
      onFinish={(v) => {
        http
          .post('/ph-admin/admin/modify-password', {
            username: form.getFieldValue('username'),
            nickname: form.getFieldValue('nickname'),
            currentPass: form.getFieldValue('currentPass'),
            newPass: form.getFieldValue('newPass'),
          } as FieldType)
          .then(() => message.success('个人信息修改成功'))
          .catch((e) => {
            message.error(e.response.data as string);
          });
      }}
    >
      <h1>修改个人信息</h1>
      <Form.Item<FieldType> label='登录用户名' name='username'>
        <Input disabled />
      </Form.Item>
      <Form.Item<FieldType>
        label='昵称'
        name='nickname'
        rules={[
          {
            required: true,
            message: '请输入昵称',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item<FieldType>
        label='请输当前密码'
        name='currentPass'
        rules={[{ required: true, message: '请输当前密码' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item<FieldType>
        label='请输入新密码'
        name='newPass'
        validateFirst
        dependencies={['newPass2']}
        rules={[
          { required: true, message: '请输入新密码' },
          {
            validator: (r) => {
              // console.log(r);
              const formV = form.getFieldsValue() as FieldType;
              return passwordCheck(formV.newPass);
            },
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item<FieldType>
        label='请再次输入新密码'
        name='newPass2'
        validateFirst
        dependencies={['newPass']}
        rules={[
          { required: true, message: '请再次输入新密码' },
          {
            validator: (r) => {
              // console.log(r);
              const formV = form.getFieldsValue() as FieldType;
              if (formV.newPass2 !== formV.newPass) {
                return Promise.reject('两次输入密码不一致');
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item<FieldType>
        wrapperCol={{
          offset: 5,
          span: 16,
        }}
      >
        <Button type='primary' htmlType='submit'>
          修改
        </Button>
      </Form.Item>
    </Form>
  );
}
