import { createContext, useReducer } from "react";
import React from "react";

type PageContextT = {
  RightsCardList: {
    binded: boolean;
    cardId: string;
    common: string;
  },
  provinceMap : object,
  cityMap : object,
  districtMap : object,
  locationChoice : Array<number>,
  range : Map<any, any>,
  userInfo : Map<any, any>
};

type PageContextActionT =
  | { type: "set-binded"; binded: boolean }
  | { type: "set-cardid"; cardId: string }
  | { type: "set-common"; common: string }
  | { type: "set-province"; provinceMap: object }
  | { type: "set-city"; cityMap: object }
  | { type: "set-district"; districtMap: object }
  | { type: "set-location"; locationChoice: Array<number> }
  | { type: "set-range"; range: Map<any, any> }
  | { type: "set-user-info"; userInfo: Map<any, any> };
  

const initialPageContext: PageContextT = {
  RightsCardList: {
    binded: false,
    cardId: "",
    common: "",
  },
  provinceMap : {},
  cityMap : {},
  districtMap : {},
  locationChoice : [],
  range : new Map<any, any>,
  userInfo : new Map<any, any>
};

function pageContextReducer(
  ctx: PageContextT,
  action: PageContextActionT
): PageContextT {
  if (process.env.NODE_ENV === "development") {
    console.log(ctx);
    console.log(action);
  }

  switch (action.type) {
    case "set-binded": {
      const obj = { ...ctx };
      obj.RightsCardList.binded = action.binded;
      return obj;
    }
    case "set-cardid": {
      const obj = { ...ctx };
      obj.RightsCardList.cardId = action.cardId;
      return obj;
    }
    case "set-common": {
      const obj = { ...ctx };
      obj.RightsCardList.common = action.common;
      return obj;
    }
    case "set-location": {
      const obj = { ...ctx };
      obj.locationChoice = action.locationChoice;
      return obj;
    }
    case "set-range": {
      const obj = { ...ctx };
      obj.range = action.range;
      return obj;
    }
    case "set-user-info": {
      const obj = { ...ctx };
      obj.userInfo = action.userInfo;
      return obj;
    }
    default:
      return { ...ctx };
  }
}

export function PageProvider({ children }: { children: React.ReactNode }) {
  const [ctx, dispatch] = useReducer(pageContextReducer, initialPageContext);

  return (
    <PageContext.Provider value={ctx}>
      <PageDispatchContext.Provider value={dispatch}>
        {children}
      </PageDispatchContext.Provider>
    </PageContext.Provider>
  );
}

export const PageContext = createContext<PageContextT>(initialPageContext);
export const PageDispatchContext = createContext<
  React.Dispatch<PageContextActionT>
>(() => null);
