import { createContext, useReducer } from "react";
import React from "react";

export interface RightsOrderT {
  id: number;
  userId: number;
  userName: string;
  idType: string;
  idNo: string;
  mobile: string;
  rightsName: string;
  orderTime: string;
  activateDate: string;
  cardId: string;
  state: string;
  total: number;
  comments: string;
}

type RightsOrderContextT = {
  visible: boolean;
  record: null | RightsOrderT;
};

type RightsOrderActionT = {
  type: string;
  record: null | RightsOrderT;
};

function rightsOrderReducer(
  _: RightsOrderContextT,
  action: RightsOrderActionT,
): RightsOrderContextT {
  switch (action.type) {
    case "set-process-rights-order":
      return {
        visible: true,
        record: action.record,
      };
    case "finish-process-rights-order":
      return {
        visible: false,
        record: null,
      };
    default:
      return {
        visible: false,
        record: null,
      };
  }
}

const initialRightsOrderContext: RightsOrderContextT = {
  visible: false,
  record: null,
};

export const RightsOrderContext = createContext<{
  ctx: RightsOrderContextT;
  dispatch: React.Dispatch<RightsOrderActionT>;
}>({ ctx: initialRightsOrderContext, dispatch: () => null });

export function RightsOrderProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [ctx, dispatch] = useReducer(
    rightsOrderReducer,
    initialRightsOrderContext,
  );

  return (
    <RightsOrderContext.Provider value={{ ctx, dispatch }}>
      {children}
    </RightsOrderContext.Provider>
  );
}
